export default [{
  header: 'Organization',
},
{
  title: 'Complaint Channel',
  icon: 'ExternalLinkIcon',
  route: 'organization-complaint-list',
  children: [
    {
      title: 'New',
      route: 'complaint-new',
      target: "_blank",
    },
    {
      title: 'List',
      route: 'organization-complaint-list',
    },
  ],
},
{
  title: 'Due Diligence',
  route: 'misc-coming-soon',
  icon: 'SearchIcon',
},
{
  title: 'Conflict of Interests',
  route: 'misc-coming-soon',
  icon: 'UploadIcon',
},
{
  title: 'Library',
  route: 'misc-coming-soon',
  icon: 'FolderPlusIcon',
},
{
  title: 'Lobby',
  icon: 'UsersIcon',
  route: 'organization-lobby-list',
},
{
  title: 'Employees',
  icon: 'UserIcon',
  route: 'organization-worker-list',
  children: [
    {
      title: 'Users',
      route: 'organization-worker-list',
    },
    {
      title: 'Locations',
      route: 'organization-location-list',
    },
    {
      title: 'Roles',
      route: 'organization-role-list',
    },
  ],
},
{
  title: 'Crimes',
  icon: 'DatabaseIcon',
  route: 'organization-crime-list',
},
  /* {
  title: 'Employees',
  icon: 'FilmIcon',
  children: [{
    title: 'Users',
    route: 'organization-worker-list',
  },
  {
    title: 'Roles',
    route: 'organization-role-list',
  },
  {
    title: 'Locations',
    route: 'organization-location-list',
  },
  {
    title: 'Metadata',
    route: 'organization-metadata-list',
  },
  ],
}, */
/* {
    title: 'Confirmation Planning',
    icon: 'ClipboardIcon',
    route: 'organization-confirmation-planning-list',
  } */
]
